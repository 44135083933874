import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "craft-cms"
    }}>{`Craft CMS`}</h1>
    <h2 {...{
      "id": "add-new-site"
    }}>{`Add new site`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to your Laravel Forge Server and add a new site.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Root domain: the root domain of the new site.`}<br />{`Make sure the domain exists and a `}<inlineCode parentName="li">{`A`}</inlineCode>{` record has been added to point to the server IP.`}</li>
          <li parentName="ul">{`Project type: `}<inlineCode parentName="li">{`General PHP / Laravel`}</inlineCode><br />{`This is the preffered option for Craft CMS.`}</li>
          <li parentName="ul">{`Web Directory: `}<inlineCode parentName="li">{`/web`}</inlineCode><br />{`This is the default Craft web directory.`}</li>
          <li parentName="ul">{`PHP Version: `}<inlineCode parentName="li">{`7.3`}</inlineCode>
            <img alt="Step 1" src={require("./LaravelForge/forge-3.png")} /></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open the new site in Forge and connect it to your Github repository, where the source code is hosted. Note that we do not want install composer dependencies now. We will do that later when we install Craft.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Installation Options: `}<inlineCode parentName="li">{`false`}</inlineCode></li>
          <li parentName="ul">{`Deploy Key: `}<inlineCode parentName="li">{`false`}</inlineCode>
            <img alt="Step 2" src={require("./LaravelForge/forge-4.png")} /></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go the SSL tab in your site and generate a new `}<inlineCode parentName="p">{`Let's Encrypt`}</inlineCode>{` certificate.
`}<img alt="Step 3" src={require("./LaravelForge/forge-5.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "add-new-database"
    }}>{`Add new database`}</h2>
    <p>{`Visit `}<a parentName="p" {...{
        "href": "#database"
      }}>{`our database section`}</a>{` to create a new database. It is `}<strong parentName="p">{`highly recommended`}</strong>{` that you also create a `}<a parentName="p" {...{
        "href": "#scheduled-backups"
      }}>{`scheduled backup configuration`}</a>{` as explained in the database guide.`}</p>
    <h2 {...{
      "id": "install-craft"
    }}>{`Install Craft`}</h2>
    <ol>
      <li parentName="ol">{`Go to the Laravel Forge `}<inlineCode parentName="li">{`Environment`}</inlineCode>{` tab, and add the environment variables contained in `}<inlineCode parentName="li">{`.env.example`}</inlineCode>{` file`}</li>
      <li parentName="ol">{`Visit our `}<a parentName="li" {...{
          "href": "/ssh-keys-setup"
        }}>{`SSH key setup`}</a>{` to setup your server access. Then SSH into the server again and execute following commands. During the Craft installation, you will be asked for a admin user and password. Save these credentials in 1PW!`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer install                    # Installs deps
php craft install                   # Installs Craft
php craft project-config/apply      # Applies project settings
php craft index-assets/all          # Index assets again - Optional, but useful when asset are stored in an existing S3 bucket.
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`You should be able to login at `}<inlineCode parentName="p">{`https://new_site.com/admin`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Verify the installation was successfull.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to the GraphQL `}<inlineCode parentName="p">{`Tokens`}</inlineCode>{` tab (`}<inlineCode parentName="p">{`/admin/graphql/tokens`}</inlineCode>{`) to generate a new token for the `}<inlineCode parentName="p">{`Frontend`}</inlineCode>{` schema.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "deploy-site"
    }}>{`Deploy site`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go back to the `}<inlineCode parentName="p">{`Apps`}</inlineCode>{` tab in your site and update the `}<inlineCode parentName="p">{`Deploy Script`}</inlineCode>{` to include `}<inlineCode parentName="p">{`composer install`}</inlineCode>{`. In the future, when you deploy changes new plugins and CMS updates will be installed for you. Remember to replace the variables with actual values.`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`YOUR-SITE-DOMAIN`}</inlineCode>{`: site domain`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`cd /home/forge/YOUR-SITE-DOMAIN
git pull origin $FORGE_SITE_BRANCH
$FORGE_COMPOSER install --no-interaction --prefer-dist --optimize-autoloader
( flock -w 10 9 || exit 1
echo 'Restarting FPM...'; sudo -S service \\$FORGE_PHP_FPM reload ) 9>/tmp/fpmlock
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`At this point you can click `}<inlineCode parentName="p">{`Deploy now`}</inlineCode>{` and optionally enable Automatic deployments.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      